import React from "react";
import { Link } from "gatsby";

const Blog = () => (
  <div
    style={{
      position: "absolute",
      top: "0",
      bottom: "0",
      right: "0",
      left: "0",
      background: "var(--black)",
      display: "grid",
      "align-content": "center",
      "justify-content": "center",
      "justify-items": "center",
      gap: "1rem",
      font: "var(--mono-font)",
    }}
  >
    <span className="loading-animation">em construção</span>
    <Link
      style={{
        "text-decoration": "none",
      }}
      to="/"
    >
      <div
        style={{
          padding: "1rem",
          border: "solid 1px",
          "border-radius": "999px",
          color: "var(--white)",
        }}
      >
        voltar para início
      </div>
    </Link>
  </div>
);

export default Blog;
